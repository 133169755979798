import React, { useState, useEffect, useReducer } from 'react';
import { BrowserRouter as Router, Routes, Route, BrowserRouter, Switch } from 'react-router-dom';
import Footer from '../component/Footer';
import Navbar from '../component/Navbar';
import Signup from './Signup';
import Signin from './Signin';
import ChangePass from './ChangePass';
import Forget from './Forget';
import Ticket from './Ticket';
import Passcode from './Passcode';
import SetPasscode from './SetPasscode';
import GuestCheckout from './GuestCheckout';
import Done from './Done';
import VisitorInfo from './VisitorInfo';
import Payment from './Payment';
import PaymentOmise from './PaymentOmise';
import NotFound from './NotFoundCampaign';
import PendingPay from './PendingPay';
import PaymentFail from './PaymentFail';
import PaymentProcessing from './PaymentProcessing';
import PaymentTruewallet from './PaymentTruewallet';
import LandingPage3 from '../template3/LandingPage3';
import LandingPage4 from '../template4/LandingPage4';
import { apiLink } from '../constants/DataPublic';
import { useGlobalState } from '../global';
import LandingPageNoPackage from '../templatenopackage/LandingPageNoPackage';
import LandingSongkran from '../templateSongkran/LandingSongkran';
import LandingPage2 from '../template2/LandingPage2';
import { ModalAnnounce } from '../customHook/ModalAnnounce';


import { useTranslation } from "react-i18next";
import CookiesPopup from '../component/cookies/CookiesPopup';
import CookiesPolicy from '../component/cookies/CookiesPolicy';
import CalVatExclude from '../customHook/CalVatExclude';
// import { createGlobalState } from 'react-hooks-global-state';
// const { useGlobalState } = createGlobalState({ count: 0 });
import AddTagFunction from '../customHook/AddTagFunction';
import Cookies from "js-cookie";

const Home = () => {
  const { t } = useTranslation();
  let { addGoogleTagAW, dynamicAddScript } = AddTagFunction();

  // Language
  const [language, setLanguage] = useState(null);
  const [routePath, setRoutePath] = useGlobalState('route');
  const [inactiveCam, setInactiveCam] = useGlobalState('inactiveCam');
  const [disabledPromo, setDisabledPromo] = useGlobalState('disabledPromo');
  const [loadCampaign, setLoadCampaign] = useGlobalState('loadCampaign');
  const [businessPolicy, setBusinessPolicy] = useGlobalState('businessPolicy');
  const [template, setTemplate] = useState();
  const [result, setResult] = useState();
  const [ggUser, setGGUser] = useState({});
  const [fbUser, setFBUser] = useState({});
  const [userSignup, setUserSignup] = useState({});
  const [cartItems, setCartItems] = useState(0);
  const [userCode, setUserCode] = useState('');
  const [packageSubTypeCode, setPackageSubTypeCode] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [acceptCookies] = useGlobalState('cookiesConsent');

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (!isLoading) {
      togglePopup()
    }
  }, [isLoading])

  let myCart = sessionStorage.getItem('MyCart');
  myCart = JSON.parse(myCart);

  let Promotion = sessionStorage.getItem('Promo');
  Promotion = JSON.parse(Promotion);

  let sesRoute = sessionStorage.getItem('Route');
  sesRoute = JSON.parse(sesRoute);

  //version
  useEffect(() => {
    sessionStorage.setItem('version', JSON.stringify('202405211046'));
  }, []);

  useEffect(() => {
    // getAllPackage()
    let sestoRoute = ['signin', 'pass', 'forget', 'changepw', 'signup', 'setpw', 'ticket', 'guestCheckout', 'done', 'visitorInfo', 'payment', 'pending', 'paymentfail', 'paymentProcess', 'cookies-policy'];
    if (!sestoRoute.includes(window.location.pathname.split('/')[1])) {
      if (sesRoute != window.location.pathname.split('/')[1]) {
        // sessionStorage.setItem('MyCart', JSON.stringify([]));
        sessionStorage.setItem('Promo', JSON.stringify({ discountPromo: '', promoCode: '', havePro: false, packageType: '' }));
        sessionStorage.setItem('Route', JSON.stringify(window.location.pathname.split('/')[1]));
      } else {
        Promotion = Promotion === null ? { discountPromo: '', promoCode: '', havePro: false, packageType: '' } : Promotion;
        sessionStorage.setItem('Promo', JSON.stringify(Promotion));
        sessionStorage.setItem('Route', JSON.stringify(window.location.pathname.split('/')[1]));
      }
    }

    if (myCart != null && myCart.length > 0) {
      myCart = sessionStorage.getItem('MyCart');
      myCart = JSON.parse(myCart);
      setCartItems(myCart.length);
    }

    console.log("window.location.pathname.split('/')[1]", window.location.pathname.split('/')[1])
    console.log("window.location.pathname.split('/')[1]", routePath)
  }, []);

  const userLogout = (logout) => {
    if (logout) {
      setGGUser({});
      setFBUser({});
      setUserSignup({});
      setCartItems(0);
    }
  };

  // const addToHeader = (headTag) => {
  //   if (acceptCookies && JSON.parse(Cookies.get("AFCL-analytic-cookie")) && JSON.parse(Cookies.get("AFCL-marketing-cookie"))) {
  //     if (document.getElementById('headerTagAds') != null) {
  //       document.getElementById('headerTagAds').remove();
  //     }
  //     console.log("addToHeader");
  //     if (headTag != null) {
  //       var script = document.createElement('script');
  //       script.id = 'headerTagAds';
  //       script.text = headTag.replaceAll('</script>', '').replaceAll('<script>', '');
  //       console.log("script", script);
  //       document.head.appendChild(script);
  //     } else {
  //       //Google Tag
  //       var script = document.createElement('script');
  //       script.id = 'headerTagAds';
  //       // script.text = "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-5VKXHTW');" //Production
  //       script.text = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-5QSB3C5');` //UAT
  //       document.head.appendChild(script);

  //       //Line Tag
  //       var lineScript = document.createElement('script');
  //       lineScript.id = 'lineHeaderTag';
  //       lineScript.text = `(function(g,d,o){
  //       g._ltq=g._ltq||[];g._lt=g._lt||function(){g._ltq.push(arguments)};
  //       var h=location.protocol==='https:'?'https://d.line-scdn.net':'http://d.line-cdn.net';
  //       var s=d.createElement('script');s.async=1;
  //       s.src=o||h+'/n/line_tag/public/release/v1/lt.js';
  //       var t=d.getElementsByTagName('script')[0];t.parentNode.insertBefore(s,t);
  //         })(window, document);
  //     _lt('init', {
  //       customerType: 'account',
  //       tagId: '90147720-6dcd-474f-b604-09bf64403d86'
  //     });
  //     _lt('send', 'pv', ['90147720-6dcd-474f-b604-09bf64403d86']);
  //     ` //UAT
  //       document.head.appendChild(lineScript);
  //     }
  //   }
  // };

  const addToBody = (bodyTag) => {
    if (acceptCookies && JSON.parse(Cookies.get("AFCL-analytic-cookie")) && JSON.parse(Cookies.get("AFCL-marketing-cookie"))) {
      if (document.getElementById('bodyTagAds') != null) {
        document.getElementById('bodyTagAds').remove();
      }

      if (bodyTag != null) {
        var noscript = document.createElement('noscript');
        noscript.id = 'bodyTagAds';
        noscript.innerHTML = bodyTag.replaceAll('<noscript>', '').replaceAll('</noscript>', '');
        document.body.appendChild(noscript);
      } else {
        //Google Tag
        var noscript = document.createElement('noscript');
        noscript.id = 'bodyTagAds';
        // noscript.text = "<iframe src='https://www.googletagmanager.com/ns.html?id=GTM-5VKXHTW'height='0' width='0' style='display:none;visibility:hidden'></iframe>" //Production
        noscript.innerHTML = "<iframe src='https://www.googletagmanager.com/ns.html?id=GTM-5QSB3C5'height='0' width='0' style='display:none;visibility:hidden'></iframe>" //UAT
        document.body.appendChild(noscript);

        //Line Tag
        var lineNoscript = document.createElement('noscript');
        lineNoscript.id = 'lineBodyTag';
        // lineNoscript.innerHTML = `<img height="1" width="1" style="display:none" src="https://tr.line.me/tag.gif?c_t=lap&t_id=62ac12ec-fea7-442c-b1b6-cd0e7fd2235b&e=pv&noscript=1" />` //Production
        lineNoscript.innerHTML = `<img height="1" width="1" style="display:none" src="https://tr.line.me/tag.gif?c_t=lap&t_id=90147720-6dcd-474f-b604-09bf64403d86&e=pv&noscript=1" />` //UAT
        document.body.appendChild(lineNoscript);
      }
    }
  };

  useEffect(() => {

    setDisabledPromo(false)
    // let checkRoute = ['signin', 'pass', 'forget', 'changepw', 'signup', 'setpw', 'ticket', 'done', 'guestCheckout', 'visitorInfo', 'paymentProcess', 'pending', 'paymentfail', 'paymentOmise'];
    let checkRoute = []
    try {
      if (!checkRoute.includes(window.location.pathname.split('/')[1])) {
        getCampaign();
      } else {
        // addToHeader(null);
        // addToBody(null);
        setLoadCampaign(true)
        addGoogleTagAW()
      }
    } catch {
      console.log("addToBody")
    }
  }, [acceptCookies]);

  async function getCampaign() {
    let prefix = window.location.pathname.split('/')[1] == '' ? 'promotion' : window.location.pathname.split('/')[1];
    // if (prefix == 'payment' && routePath == '') {
    //   prefix = 'promotion'
    // }
    const requestOptions = {
      method: 'GET',
      headers: { 'accept-language': language },
    };
    setRoutePath('');
    setLoadCampaign(false)
    // /{culture}/api/v1/Campaign/Info
    if (window.location.pathname.split('/')[1] != 'notfound') {
      let checkRoute = ['signin', 'pass', 'forget', 'changepw', 'signup', 'setpw', 'ticket', 'done', 'guestCheckout', 'visitorInfo', 'paymentProcess', 'payment', 'pending', 'paymentfail', 'paymentOmise', 'cookies-policy'];
      if (checkRoute.includes(window.location.pathname.split('/')[1])) {
        prefix = 'promotion'
      }
      const res = await fetch(apiLink + '/afcl/Package/CampaignInfo' + '?prefix=' + prefix, requestOptions);
      res
        .json()
        .then((res) => {
          if (res['Result']['IsCampaignExpired'] != null) {
            setResult(res['Result']);
            // addToHeader(res['Result']['GoogleTagHead']);
            if (acceptCookies && JSON.parse(Cookies.get("AFCL-analytic-cookie")) && JSON.parse(Cookies.get("AFCL-marketing-cookie"))) {
              dynamicAddScript(res['Result']['GoogleTagHead'])
              addToBody(res['Result']['GoogleTagBody']);
            }
            if (prefix == 'promotion') {
              setTemplate('');
              setInactiveCam(false)
              setRoutePath('');
            } else {
              setTemplate(res['Result']['Template']);
              setInactiveCam(res['Result']['IsCampaignExpired'])
              setRoutePath('/' + window.location.pathname.split('/')[1]);
            }
            // if (window.location.pathname.split('/')[1].toLocaleLowerCase() == '16two') {
            //   setDisabledPromo(true)
            // }
            setLoadCampaign(true)
          } else {
            if (prefix != 'promotion' && !checkRoute.includes(window.location.pathname.split('/')[1])) {
              window.location.href = '/';
              window.location.href = '/notfound';
            }
          }
        })
        .catch((error) => {
          console.log(error);
          // alert('Error: API /afcl/Package/Banner ', error);
        });
    }

  }

  const getGGUser = (ggUser) => {
    console.log('ggUser', ggUser)
    setGGUser(ggUser);
  };
  const getFBUser = (fbUser) => {
    setFBUser(fbUser);
  };

  const getUserSignup = (userSignup) => {
    setUserSignup(userSignup);
  };

  const getLanguage = (lang) => {
    setLanguage(lang);
  };

  const getCartItem = (cartQty) => {
    setCartItems(cartQty);
  };

  const getPackageSubTypeCode = (subTypeCode) => {
    setPackageSubTypeCode(subTypeCode);
  };

  async function getUserCart(userCode, language) {
    console.log("Home");
    if (userCode != '' && userCode != undefined) {
      const requestOptions = {
        method: 'GET',
        headers: { 'accept-language': language },
      };
      await fetch(apiLink + '/afcl/Package/Cart/CheckOut/V2/' + userCode, requestOptions)
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          const items = data;
          if (items['Success'] === true) {
            // Cart ใหม่
            let myCart = sessionStorage.getItem('MyCart');
            myCart = JSON.parse(myCart);

            // Cart ที่ Save ไว้ก่อนหน้า
            let allCart = items['Result']['Order'];

            let user = sessionStorage.getItem('UserLogin');
            user = JSON.parse(user);
            user.confirmationNo = allCart.ConfirmationNo;
            sessionStorage.setItem('UserLogin', JSON.stringify(user));

            // รวม Cart
            let saveCart = [];
            allCart.Packages.forEach((e) => {
              if (e.ItemCode === '') {
                let cIndex =
                  myCart === null || myCart.length === 0
                    ? -1
                    : myCart.findIndex((c) => {
                      return c.PackageCode === e.PackageCode;
                    });
                if (cIndex === -1) {
                  saveCart.push({
                    PackageCode: e.PackageCode,
                    PackageName: e.PackageName,
                    ItemCode: '',
                    ItemName: '',
                    Qty: e.Qty,
                    Tax: 0,
                    ItemPrice: e.PackagePrice,
                    ItemDiscount: e.IsDiscount ? e.DiscountPrice : 0,
                    PriceName: e.PriceName,
                    PriceUnit: '',
                    TotalPrice: e.Qty * e.PackagePrice,
                    VisitDate: e.VisitDate === null ? '' : e.VisitDate,
                    VisitTime: '',
                    VisitLocation: '',
                    step: 1,
                    status: 'normal',
                  });
                } else {
                  myCart[cIndex].Qty = myCart[cIndex].Qty + e.Qty;
                }
              } else {
                let cIndex =
                  myCart === null || myCart.length === 0
                    ? -1
                    : myCart.findIndex((c) => {
                      return c.PackageCode === e.PackageCode && c.ItemCode === e.ItemCode;
                    });
                if (cIndex === -1) {
                  saveCart.push({
                    PackageCode: e.PackageCode,
                    PackageName: e.PackageName,
                    ItemCode: e.ItemCode,
                    ItemName: e.ItemName,
                    Qty: e.Qty,
                    Tax: 0,
                    ItemPrice: e.PackagePrice,
                    ItemDiscount: e.IsDiscount ? e.DiscountPrice : 0,
                    PriceName: e.PriceName,
                    PriceUnit: e.PriceUnit,
                    TotalPrice: parseInt(e.Qty) * parseInt(e.ItemPrice),
                    VisitDate: e.VisitDate,
                    VisitTime: e.VisitTime,
                    VisitLocation: e.VisitLocation,
                    step: 1,
                    status: 'normal',
                  });
                } else {
                  myCart[cIndex].Qty = myCart[cIndex].Qty + e.Qty;
                }
              }
            });
            myCart = myCart === null || myCart.length === 0 ? [...saveCart] : [...myCart, ...saveCart];
            setCartItems(myCart.length);
            console.log("myCart", myCart);
            myCart.forEach((pk) => {
              CalVatExclude(pk, businessPolicy)
            })
            // const modelExcludeVat = CalVatExclude(myCart, businessPolicy)
            // console.log("modelExcludeVat", modelExcludeVat);
            sessionStorage.setItem('MyCart', JSON.stringify(myCart));
          } else {
          }
        })
        .catch((error) => {
          console.log('Error: ', error);
        });
    }
  }

  useEffect(() => {
    if (userCode != '') {
      getUserCart(userCode, language);
    } else setCartItems(0);
  }, [userCode]);


  const getBusinessPolicy = async () => {
    const requestOptions = {
      method: 'GET',
      headers: { 'accept-language': language },
    };
    // /BusinessPolicy/Web
    const res = await fetch(apiLink + '/BusinessPolicy/Web', requestOptions);
    res
      .json()
      .then((res) => {
        console.log("getBusinessPolicyRes", res);
        console.log("getBusinessPolicyPolicy", res['Result']['Policy']);
        setBusinessPolicy(res['Result']['Policy']);
        // const pk = {
        //   "PackageCode": "AFPK001",
        //   "PackageName": "คาบาน่า ขนาดปกติ",
        //   "ItemCode": "",
        //   "ItemName": "",
        //   "Qty": 2,
        //   "Tax": 0,
        //   "ItemPrice": 1900,
        //   "ItemDiscount": 2000,
        //   "PriceName": "THB",
        //   "PriceUnit": "",
        //   "TotalPrice": 3800,
        //   "VisitDate": "",
        //   "VisitTime": "",
        //   "VisitLocation": "",
        //   "step": 1,
        //   "status": "normal"
        // }
        // const modelExcludeVat = CalVatExclude(pk, res['Result']['Policy'])
        // console.log("modelExcludeVat", modelExcludeVat);
      })
      .catch((error) => {
        console.log(error);
        // alert('Error: API /afcl/Package/Banner ', error);
      });
  }

  useEffect(() => {
    getBusinessPolicy()
  }, []);



  const Landing = () => {
    switch (template) {
      case 'Template1':
        // return <LandingPageNoPackage is16Two={routePath.replace('/', '').toLocaleLowerCase() === '16two' ? true : false} language={language} getPackageSubTypeCode={getPackageSubTypeCode} />;
        return <LandingSongkran language={language} getPackageSubTypeCode={getPackageSubTypeCode} setIsLoading={setIsLoading} />;
      case 'Template2': //koh tula
        return <LandingPage2 language={language} getPackageSubTypeCode={getPackageSubTypeCode} />;
      // return <LandingPage4 language={language} getPackageSubTypeCode={getPackageSubTypeCode} />;
      case 'Template3':
        return <LandingPage3 language={language} getPackageSubTypeCode={getPackageSubTypeCode} setIsLoading={setIsLoading} />;
      case 'Template4':
        return <LandingPage4 language={language} getPackageSubTypeCode={getPackageSubTypeCode} setIsLoading={setIsLoading} />;
      case 'Template5': //16Two
        return <LandingPageNoPackage language={language} getPackageSubTypeCode={getPackageSubTypeCode} />;
      default:
        return <LandingPage4 language={language} getPackageSubTypeCode={getPackageSubTypeCode} setIsLoading={setIsLoading} />;
    }
  };

  return (
    <div>
      {/* "homepage": "/afcl", */}
      {/* <Router basename={'/'}> */}
      <Router basename={'/' + routePath}>
        <Navbar getLanguage={getLanguage} cartItems={cartItems} userLogout={userLogout} setUserCode={setUserCode} />
        <Routes>
          <Route path={'/'} element={Landing()} exact />
          <Route path='/notfound' element={<NotFound language={language} getPackageSubTypeCode={getPackageSubTypeCode} />} />
          <Route path='/pending' element={<PendingPay language={language} getPackageSubTypeCode={getPackageSubTypeCode} />} />
          <Route path='/paymentfail' element={<PaymentFail language={language} getPackageSubTypeCode={getPackageSubTypeCode} />} />
          <Route path='/paymentProcess' element={<PaymentProcessing language={language} getPackageSubTypeCode={getPackageSubTypeCode} />} />
          <Route path="/signin" element={<Signin language={language} getGGUser={getGGUser} getFBUser={getFBUser} setUserCode={setUserCode} />} />
          <Route path="/pass" element={<Passcode language={language} setUserCode={setUserCode} />} />
          <Route path="/forget" element={<Forget language={language} />} />
          <Route path="/changepw" element={<ChangePass language={language} />} />
          <Route path="/signup" element={<Signup getUserSignup={getUserSignup} ggUser={ggUser} fbUser={fbUser} />} />
          <Route path="/setpw" element={<SetPasscode userSignup={userSignup} />} />
          <Route path="/ticket/:packageCode/:step" element={<Ticket language={language} packageSubTypeCode={packageSubTypeCode} getCartItem={getCartItem} />} />
          <Route path="/guestCheckout" element={<GuestCheckout language={language} getGGUser={getGGUser} getFBUser={getFBUser} setUserCode={setUserCode} />} />
          <Route path="/done" element={<Done language={language} getCartItem={getCartItem} />} />
          <Route path="/visitorInfo" element={<VisitorInfo language={language} getGGUser={getGGUser} getFBUser={getFBUser} getCartItem={getCartItem} setUserCode={setUserCode} />} />
          <Route path="/payment" element={<Payment language={language} getCartItem={getCartItem} />} />
          <Route path="/paymentOmise" element={<PaymentOmise language={language} getCartItem={getCartItem} />} />
          <Route path="/paymentOmise/trueMoney" element={<PaymentTruewallet language={language} getCartItem={getCartItem} />} />
          <Route path="/cookies-policy" element={<CookiesPolicy language={language} />} />
        </Routes>
      </Router>
      {window.location.pathname.split('/')[1].toLocaleLowerCase() === '16two' && window.location.pathname.split('/').length === 2 && template === 'Template5' || window.location.pathname.split('/')[2] === '' && window.location.pathname.split('/')[1].toLocaleLowerCase() === '16two' && template === 'Template5' ? (
        // <Footer />
        <>
        </>
      ) : (
        <Footer />
      )}
      <CookiesPopup />
      {/* <div className={`bottom-popup ${isOpen ? 'open' : ''}`}>
        <p className='text-description-white'>{t('text_bottom_popup')}</p>
        <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
          <button className='button-close-popup' onClick={togglePopup}>{t('close')}</button>
        </div>
      </div> */}
      {/* <ModalAnnounce /> */}
    </div>
  );
};

export default Home;
