import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Countdown, { zeroPad } from "react-countdown";
import Button from '@mui/material/Button';
import { apiLink, baseLink, checkStatusTimerMS } from "../constants/DataPublic";
import { ReactComponent as Close } from "../component/img/close.svg";
import AddTagFunction from "../customHook/AddTagFunction";
//Image
import HeaderQR from "../component/img/ThaiQRPay.png";
import logoQR from "../component/img/prompt-pay-logo.png";
import QR from "../component/img/qr_code_barcode.jpg";

const Payment = (props) => {
  const { addConversionScript } = AddTagFunction()

  let user = sessionStorage.getItem('UserLogin')
  user = JSON.parse(user)

  let myCart = sessionStorage.getItem('MyCart')
  myCart = JSON.parse(myCart)

  let Promo = sessionStorage.getItem('Promo')
  Promo = JSON.parse(Promo)

  let paymentHis = sessionStorage.getItem('paymentHis')
  paymentHis = JSON.parse(paymentHis)

  const params = new URLSearchParams(window.location.search)

  const { language } = props
  const [webPaymentUrl, setWebPaymentUrl] = useState("")
  const [invoiceNo, setInvoiceNo] = useState("")
  const [total, setTotal] = useState(0)
  const [qrExpired, setQrExpired] = useState(600000)
  const [paymentSuccess, setPaymentSuccess] = useState(null)
  const [showAlert, setShowAlert] = useState(false);
  const [loadBackdrop, setLoadBackdrop] = useState(true);
  const [alertText, setAlertText] = useState("");
  const [callCount, setCallCount] = useState(false);

  const navigate = useNavigate()

  useEffect(() => {
    console.log("Payment: ", user);
    let confirm = params.get('ConfirmationNo') //
    let userCode = params.get('UserCode') //
    console.log("confirm", confirm)
    if (paymentHis && !confirm && !userCode) {
      navigate("/ticket/" + myCart[0].PackageCode + "/5")
      sessionStorage.removeItem('paymentHis')
    } else {
      if (user != null && user != undefined) {
        setTotal(user.totalPrice)
        sessionStorage.removeItem('paymentHis')
        if (confirm && userCode) {
          setInvoiceNo(true)
          clearCart(false)
        } else {
          getPayment()
        }
      } else navigate('/')
    }

  }, [])

  useEffect(() => {
    if (user.InvoiceNumber != "") {
      const interval = setInterval(() => {
        clearCart(true)
      }, checkStatusTimerMS)
      return () => clearInterval(interval)
    }
  }, [user.InvoiceNumber])

  const getPayment = async () => {
    // const requestOptions = {
    //   method: "GET",
    //   headers: { "accept-language": language}
    // };
    // await fetch(
    //   user.paymentUrl,
    //     requestOptions
    // )
    // /afcl/Package/AllPayment
    // let link = user.paymentMethod == 'QRCode' ? '/afcl/Package/PayQr' : user.paymentMethod == 'Installment' ? '/afcl/Package/PayIPPOnline' : '/afcl/Package/PayOnline';
    sessionStorage.setItem('paymentHis', JSON.stringify('1'));

    const requestOptions = {
      method: "POST",
      headers: { "accept-language": language, 'Content-Type': 'application/json', },
      body: JSON.stringify({
        "ConfirmationNo": user.confirmationNo,
        "UserCode": user.userCode,
      }),
    };
    await fetch(
      apiLink + '/afcl/Package/AllPayment',
      requestOptions
    )

      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        if (data["Success"]) {
          const items = data["Result"];
          console.log("getPayment", data);
          console.log("WebPaymentUrl", items["WebPaymentUrl"]);
          console.log("InvoiceNumber", items["InvoiceNumber"]);
          user.InvoiceNumber = items["InvoiceNumber"];
          sessionStorage.setItem('UserLogin', JSON.stringify(user));
          // sessionStorage.setItem('UserLogin', JSON.stringify({InvoiceNumber: items["InvoiceNumber"]}));
          window.location.href = items["WebPaymentUrl"];
          // setWebPaymentUrl(items["WebPaymentUrl"])
          // if (user.paymentMethod === "Credit Card") {
          // setWebPaymentUrl(items["WebPaymentUrl"])
          // setInvoiceNo(items["InvoiceNumber"])
          // } else if (user.paymentMethod === "QRCode") {
          //   setWebPaymentUrl(items["QrData"]["data"])
          //   console.log("QrData", items["QrData"]["data"])
          //   setInvoiceNo(items["InvoiceNumber"])
          //   setQrExpired(items["QrData"]["expiryTimer"] == null ? 600000 : parseInt(items["QrData"]["expiryTimer"]))
          // } else if (user.paymentMethod === "Installment") {
          //   setWebPaymentUrl(items['WebPaymentUrl']);
          //   setInvoiceNo(items['InvoiceNumber']);
          // } else {
          //   setWebPaymentUrl("")
          //   setInvoiceNo("")
          //   setTotal(0)
          //   console.log("bad request")
          // }
        } else {
          console.log("bad request", data["Result"])
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        // alert('Error: API ', user.paymentUrl , error)
      });
  }

  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      // Render a complete state
      return <span></span>
    } else {
      // Render a countdown
      return (
        <span>
          {zeroPad(minutes)}:{zeroPad(seconds)}
        </span>
      );
    }
  };

  const clearCart = async (showModal, retries = 5) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        "ConfirmationNo": user.confirmationNo,
        "UserCode": user.userCode
      })
    };

    console.log(requestOptions)
    await fetch(
      apiLink + "/afcl/Package/Pay/CheckStatus",
      requestOptions
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        const items = data;
        console.log("PaymentStatus", items);
        if (items["Success"]) {
          if (items["Result"]["ResponseCode"] === "0000") {
            // Successful
            let tax = 0
            myCart.forEach(element => {
              tax += element.Tax
              console.log('element', element.Tax)
            });
            console.log('tax', tax)

            let product = []
            myCart.forEach((e) => {
              let obj = {
                name: e.PackageName,
                id: e.PackageCode,
                price: e.ItemPrice.toFixed(2),
                category: 'Package',
                quantity: e.Qty,
                coupon: Promo.promoCode
              }
              product.push(obj)
            })

            // for Google tag (GTM)
            var dataLayer = window.dataLayer || [];
            dataLayer.push({
              'event': 'transaction',
              'ecommerce': {
                'purchase': {
                  'actionField': {
                    'id': user.confirmationNo,
                    'affiliation': user.channelCode,
                    'revenue': user.totalPrice.toFixed(2),
                    'Tax': tax.toFixed(2),
                    'shipping': '',
                    'currency': 'THB',
                    'coupon': Promo.promoCode
                  },
                  'products': product
                },
              }
            });
            console.log('dataLayer', dataLayer)
            addToHeader()

            addConversionScript()

            sessionStorage.removeItem("MyCart")
            sessionStorage.removeItem("Promo")
            navigate('/done', {
              state: {
                confirmationNo: user.confirmationNo,
                TagHtml: items["Result"]["TagHtml"]
              }
            })

            // navigate("/done",{confirmationNo:user.confirmationNo}) 
          } else if (items["Result"]["ResponseCode"] === "4005") {
            // Do not honor
            navigate("/paymentfail")
            // navigate("/ticket/" + myCart[0].PackageCode + "/5")
          } else if (items["Result"]["ResponseCode"] === "2001") {
            // Transaction in progress 
            // clearCart(false)

            //old logic
            setLoadBackdrop(false)
            setShowAlert(true)
            setAlertText("Transaction fail please try again.")

            // setTimeout(() => {
            //   clearCart(false)
            // }, 3000)

            if (retries != 0) {
              setTimeout(() => {
                clearCart(false, --retries)
              }, 4000)
            } else {
              setLoadBackdrop(false)
              setShowAlert(true)
              setAlertText("Transaction fail please try again.")

              setTimeout(() => {
                navigate("/ticket/" + myCart[0].PackageCode + "/5")
                window.location.reload()
              }, 5000);

            }

          } else if (items["Result"]["ResponseCode"] === "0001") {
            // Transaction pending 
            navigate("/pending")
            sessionStorage.removeItem("MyCart")
            sessionStorage.removeItem("Promo")
          } else if (items["Result"]["ResponseCode"] === "4081") {
            // Transaction pending 
            navigate("/paymentfail")
          } else {
            navigate("/paymentfail")
            // if (showModal) {
            //   // setAlertText("Please contact administrator.")
            //   setAlertText("Transaction fail please try again.")
            //   setShowAlert(true)
            //   setTimeout(() => {
            //     navigate("/paymentfail")
            //   }, 3000)
            // }
          }
        } else {
          navigate("/paymentfail")
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        // alert('Error: API /afcl/Package/Pay/CheckStatus ' , error)
      })
  }

  const addToHeader = () => {
    var scriptElement = document.getElementById('headerTagAds')
    if (scriptElement) {
      scriptElement.text.includes('_lt')
      console.log("scriptElement.text.includes('_lt')", scriptElement.text.includes('_lt'));
      if (scriptElement.text.includes('_lt')) {
        var lineScript = document.createElement('script');
        lineScript.id = 'LinePaymentSuccess';
        // lineScript.text = `_lt('send', 'cv', {
        //   type: 'Conversion'
        // },['62ac12ec-fea7-442c-b1b6-cd0e7fd2235b']);` // production
        lineScript.text = `_lt('send', 'cv', {
          type: 'PaymentSuccess'
        },['90147720-6dcd-474f-b604-09bf64403d86']);` //UAT
        document.head.appendChild(lineScript);
      }
    }
  }

  const goBack = async () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        "ConfirmationNo": user.confirmationNo,
        "UserCode": user.userCode
      })
    };

    console.log(requestOptions)
    await fetch(
      apiLink + "/afcl/Package/Pay/CheckStatus",
      requestOptions
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        const items = data;
        console.log("PaymentStatus", items);
        if (items["Success"]) {
          console.log("Back", items["Result"]["ResponseCode"]);
          if (items["Result"]["ResponseCode"] === "0000") {
            // Successful
            sessionStorage.removeItem("MyCart")
            navigate("/done", {
              state: {
                confirmationNo: user.confirmationNo
              }
            })
          } else {
            // Do not honor
            navigate("/ticket/" + myCart[0].PackageCode + "/5")
          }
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        // alert('Error: API /afcl/Package/Pay/CheckStatus ' , error)
      })
  }

  return (
    <div className="page homepage">
      <div className="section">
        <div className="container">
          <div className="col-xl-6 col-lg-8 col-md-9 col-sm-12" style={{ margin: '0 auto' }}>
            {webPaymentUrl && user != null || !loadBackdrop ? (
              ''
            ) : (
              <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
                <CircularProgress color="inherit" />
              </Backdrop>
            )}

            {webPaymentUrl !== '' && user != null && myCart.length > 0 && (
              <div className="row">
                <iframe className="card-payment pb-3" src={webPaymentUrl}></iframe>
                {/* <object data={webPaymentUrl} width="400" height="700" type="text/html"></object> */}
                {/* <embed src={webPaymentUrl} width={200} height={200} /> */}
                <div className="row">
                  <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12">
                    <span className="weight-600">Invoice Number :</span> {invoiceNo}
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 paymen-text">
                    <span className="weight-600">Total :</span> {total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                  </div>
                </div>

                <div className="row">
                  <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 weight-600">
                    <span className="text-center mt-4 payment" style={{ cursor: 'pointer' }} onClick={goBack}>
                      {' '}
                      BACK{' '}
                    </span>
                  </div>
                  {/* <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12 weight-600 paymen-text">
                    <span className="text-center mt-4 payment" style={{cursor: "pointer"}} onClick={() => clearCart(true)}>Click here! when payment succeed.</span>
                  </div> */}
                </div>
              </div>
            )}

            {/* {webPaymentUrl !== '' && user != null && user.paymentMethod === 'Installment' && myCart.length > 0 && (
              <div className="row">
                <iframe className="card-payment pb-3" src={webPaymentUrl}></iframe>
                <div className="row">
                  <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12">
                    <span className="weight-600">Invoice Number :</span> {invoiceNo}
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 paymen-text">
                    <span className="weight-600">Total :</span> {total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                  </div>
                </div>

                <div className="row">
                  <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 weight-600">
                    <span className="text-center mt-4 payment" style={{ cursor: 'pointer' }} onClick={goBack}>
                      {' '}
                      BACK{' '}
                    </span>
                  </div>
                </div>
              </div>
            )} */}
          </div>

          {/* QR CODE */}
          {/* {webPaymentUrl !== '' && user != null && user.paymentMethod === 'QRCode' && myCart.length > 0 && (
            <div className="row" style={{ justifyContent: 'center' }}>
              <div className="col-xl-3 col-lg-3 col-md-5 col-sm-12">
                <div style={{ border: '1px solid #0e3e68' }}>
                  <img src={HeaderQR} width="100%" />
                  <div className="px-3 py-3 text-center">
                    <img src={logoQR} width="30%" className=" mb-3" />
                    <img src={webPaymentUrl} width="100%" />
                  </div>
                </div>

                <div className="text-center py-3">
                  Remaining time:&nbsp;
                  <Countdown
                    date={Date.now() + qrExpired}
                    renderer={renderer}
                    onComplete={() => {
                      window.location.reload();
                    }}
                  />
                </div>

                <div className="col-12">
                  <span className="weight-600">Invoice Number :</span>
                  <br />
                  {invoiceNo}
                </div>
                <div className="col-12">
                  <span className="weight-600">Total :</span> {total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                </div>

                <div className="col-12 weight-600 pt-3">
                  <span className="text-center mt-4 payment" style={{ cursor: 'pointer' }} onClick={goBack}>
                    {' '}
                    BACK{' '}
                  </span>
                </div>
              </div>
            </div>
          )} */}
          <Modal show={showAlert} className="col-12 cart-placeorder">
            <a href="#" className="close">
              <Close className="popup-close" onClick={() => { setShowAlert(false); navigate("/ticket/" + myCart[0].PackageCode + "/5") }} />
            </a>
            <h5 className="text-center text-danger">{alertText}</h5>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default Payment;
